import {call, takeLatest, takeEvery, debounce, put} from 'redux-saga/effects';
import axios from 'axios';
import queryString from 'query-string';

import { isFunc } from '@utils/functions';
import {actionTypes as userActionType} from "@user/reducers";

const Api = {
  getAccounts: ({ page, limit, sort }) => {
    let params = { limit: limit ?? 100 };
    if (page || page === 0) params.page = page;
    if (sort) params.sort = sort;

    return axios.request({
      url: '/accounts',
      method: 'get',
      params
    });
  },
  getAccount: (id) => axios.get(`/accounts/${id}`),
  getPublicAccount: (id) => axios.get(`/accounts/public/${id}`),
  getAccountByUid: (uid) => {
    const queryParams = queryString.stringify({ uid });
    return axios.get(`/accounts/uid?${queryParams}`);
  },
  createAccount: (body) => axios.post(`/accounts`, body),
  updateAccount: ({id, body}) => axios.put(`/accounts/${id}`, body),
  deleteAccount: (id) => axios.delete(`/accounts/${id}`),
  uploadLogo: ({ formData, config, id }) => axios.post(`/accounts/uploadLogo/${id}`, formData, { ...config, headers: { 'Content-Type': 'multipart/form-data' } }),
  getReferralLinks: () => axios.get(`/accounts/referralLinks`),
};

export const actionTypes = {
  GET_ACCOUNTS: 'GET_ACCOUNTS',
  GET_ACCOUNT: 'GET_ACCOUNT',
  GET_PUBLIC_ACCOUNT: 'GET_PUBLIC_ACCOUNT',
  GET_ACCOUNT_BY_UID: 'GET_ACCOUNT_BY_UID',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
  UPDATE_ACCOUNT: 'UPDATE_ACCOUNT',
  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
  UPLOAD_LOGO: 'UPLOAD_LOGO',
  GET_REFERRAL_LINKS: 'GET_REFERRAL_LINKS',
};

function* sagaGetAccounts({ payload: { limit, page, sort, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getAccounts, { limit, page, sort });
    if (data.elements) {
      if (isFunc(onSuccess)) onSuccess(data);
    } else if (isFunc(onError)) onError(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetAccount({ payload: { id, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getAccount, id);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetPublicAccount({ payload: { id, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getPublicAccount, id);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetAccountByUid({ payload: { uid, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getAccountByUid, uid);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaCreateAccount({ payload: { body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.createAccount, body);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUpdateAccount({ payload: { id, body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.updateAccount, { id, body });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaDeleteAccount({ payload: { id, onSuccess, onError } }) {
  try {
    const result = yield call(Api.deleteAccount, id);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetReferralLinks({ payload: { onSuccess, onError } }) {
  try {
    const result = yield call(Api.getReferralLinks);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUploadLogo({ payload: { imageFile, id, onSuccess, onError, onProgressEvent } }) {
  try {
    const config = {
      onUploadProgress: function(progressEvent) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        if (isFunc(onProgressEvent)) onProgressEvent(percentCompleted);
      }
    };

    const formData = new FormData();
    formData.append('logo', imageFile);
    const result = yield call(Api.uploadLogo, { formData, config, id });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

export const getAccounts = payload => ({ type: actionTypes.GET_ACCOUNTS, payload });
export const getAccount = payload => ({ type: actionTypes.GET_ACCOUNT, payload });
export const getPublicAccount = payload => ({ type: actionTypes.GET_PUBLIC_ACCOUNT, payload });
export const getAccountByUid = payload => ({ type: actionTypes.GET_ACCOUNT_BY_UID, payload });
export const createAccount = payload => ({ type: actionTypes.CREATE_ACCOUNT, payload });
export const updateAccount = payload => ({ type: actionTypes.UPDATE_ACCOUNT, payload });
export const deleteAccount = payload => ({ type: actionTypes.DELETE_ACCOUNT, payload });
export const uploadLogo = payload => ({ type: actionTypes.UPLOAD_LOGO, payload });
export const getReferralLinks = payload => ({ type: actionTypes.GET_REFERRAL_LINKS, payload });

const accountsSagas = [
  takeLatest(actionTypes.GET_ACCOUNTS, sagaGetAccounts),
  takeEvery(actionTypes.GET_ACCOUNT, sagaGetAccount),
  takeLatest(actionTypes.GET_PUBLIC_ACCOUNT, sagaGetPublicAccount),
  takeLatest(actionTypes.GET_ACCOUNT_BY_UID, sagaGetAccountByUid),
  takeEvery(actionTypes.CREATE_ACCOUNT, sagaCreateAccount),
  takeEvery(actionTypes.UPDATE_ACCOUNT, sagaUpdateAccount),
  takeEvery(actionTypes.DELETE_ACCOUNT, sagaDeleteAccount),
  takeLatest(actionTypes.UPLOAD_LOGO, sagaUploadLogo),
  takeLatest(actionTypes.GET_REFERRAL_LINKS, sagaGetReferralLinks),
];

export default accountsSagas;