import React, { lazy } from 'react';
import { AuthRoute } from '../auth/components';

//import sagas from './sagas';
import { USER_ROLE } from '@utils/authorisation';


//const AccountAndTeam = lazy(() => import('./pages/AccountAndTeam'));

const partnersModule = {
  routes: [
    /*<AuthRoute key="partners-account-and-team" exact path="/partners/account-and-team" component={AccountAndTeam} headerType={'private'} isPartnerOnly />*/
  ],
  userMenuItems: [
    /*{
      name: 'Account & Team',
      path: '/partners/account-and-team',
      allowedRoles: [USER_ROLE.Partner_Member, USER_ROLE.Partner_Supervisor, USER_ROLE.Partner_Admin],
      isForPartner: true,
      notForPartnerCustomer: true,
      order: 7
    },*/
  ],
  //sagas
};

export default partnersModule;