import React, { lazy } from 'react';
import { AuthRoute } from '../auth/components';

import sagas from './sagas';
import { USER_ROLE } from '@utils/authorisation';


const AccountsDashboard = lazy(() => import('./pages/AccountsDashboard'));
const AccountCreatePage = lazy(() => import('./pages/AccountCreatePage'));
const AccountEditPage = lazy(() => import('./pages/AccountEditPage'));
const PartnerAccountEditPage = lazy(() => import('./pages/PartnerAccountEditPage'));

const accountsModule = {
  routes: [
    <AuthRoute key="accounts" exact path="/accounts" component={AccountsDashboard} headerType={'private'} isSpecialistOnly allowedRoles={[USER_ROLE.Admin]} />,
    <AuthRoute key="account-create" exact path="/account-create" component={AccountCreatePage} headerType={'private'} isSpecialistOnly allowedRoles={[USER_ROLE.Admin]} />,
    <AuthRoute key="account-edit" exact path="/account-edit/:accountID" component={AccountEditPage} headerType={'private'} isSpecialistOnly allowedRoles={[USER_ROLE.Admin]} />,
    <AuthRoute key="partners-account-and-team" exact path="/partner-account-edit" component={PartnerAccountEditPage} headerType={'private'} isPartnerOnly allowedRoles={[USER_ROLE.Partner_Admin]} />
  ],
  userMenuItems: [
    {
      name: 'Account & Team',
      path: '/partner-account-edit',
      allowedRoles: [USER_ROLE.Partner_Admin],
      isForPartner: true,
      notForPartnerCustomer: true,
      order: 7
    },
    {
      name: 'Accounts',
      path: '/accounts',
      allowedRoles: [USER_ROLE.Admin],
      isForSpecialist: true,
      order: 21
    }
  ],
  sagas
};

export default accountsModule;
