const defaultState = {
  featureFlags: undefined
};

const featureFlagsActionTypes = {
  SET_FEATURE_FLAGS: 'SET_FEATURE_FLAGS',
};

const featureFlagsActions = function (state = defaultState, { type, payload }) {
  switch (type) {
    case featureFlagsActionTypes.SET_FEATURE_FLAGS:
      return {
        ...state,
        featureFlags: payload
      };
    default:
      return state;
  }
};

export default featureFlagsActions;

// ACTIONS
export const setFeatureFlags = payload => ({
  type: featureFlagsActionTypes.SET_FEATURE_FLAGS,
  payload
});