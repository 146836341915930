import { call, takeLatest, takeEvery, debounce, put, all } from 'redux-saga/effects';
import axios from 'axios';
import queryString from 'query-string';

import { isFunc } from '@utils/functions';
import { actionTypes as registrationActionType } from '../reducers';

const Api = {
  getLendProviders: ({ searchString, ids, limit, type, inOurPanel, notExcluded, page, sort }) => {
    const idsArray = ids?.length ? ids.reduce((acc, id) => `${acc}&id[]=${id}`, '') : '';
    return axios.get(`/dictionary/lenders?limit=${limit ?? '5'}&page=${!!page ? page : '1'}${searchString ? '&searchString=' + searchString : ''}${idsArray}${type ? '&product_type=' + type : ''}${inOurPanel ? '&inOurPanel=true' : ''}${notExcluded ? '&notExcluded=true' : ''}${!!sort?.trim?.()?.length ? `&sort=${sort}` : ''}`);
  },
  updateLendProvider: ({ body, id }) => axios.put(`/lenders/${id}`, body),
  getSuperProviders: (searchString) => axios.get(`/dictionary/superfunds${searchString ? '?searchString=' + searchString : ''}`),
  getRepaymentEstimation: (data) => axios.post('/services/basicrepayments', data),
  getDealFeatures: () => axios.get('/dictionary/dealFeatures'),
  findDeals: ({ lenderType, rateType, repaymentOptions, features, price, deposit, purpose, reason, firstHomeBuyer, page, sort, lenderIds, dealId, dealIds,
                fixedTermMonths, bankType, preferenceInterestRate, preferenceCashBack, limit, personalised, onlyPersonalisedLenders, cashback, excludedLenders }) => {
    const queryParams = queryString.stringify({
      limit: limit ?? 10,
      page,
      sort,
      lenderType,
      rateType,
      repaymentOptions,
      price: (!!price || price === 0) ? +Number(price).toFixed(2) : undefined,
      deposit: (!!deposit || deposit === 0) ? +Number(deposit).toFixed(2) : undefined,
      purpose,
      reason,
      firstHomeBuyer,
      dealId,
      fixedTermMonths,
      bankType,
      preferenceInterestRate,
      preferenceCashBack,
      personalised,
      onlyPersonalisedLenders,
      cashback: cashback?.toString() ?? undefined,
      excludedLenders
    });
    const featuresArray = features?.length ? features.reduce((acc, f) => `${acc}&features[]=${encodeURI(f)}`, '') : '';
    const lenderIdsArray = lenderIds?.length ? lenderIds.reduce((acc, lenderId) => `${acc}&lender[]=${lenderId}`, '') : '';
    const excludedLenderIdsArray = excludedLenders?.length ? excludedLenders.reduce((acc, lenderId) => `${acc}&excludedLenders[]=${lenderId}`, '') : '';
    const dealsIdsArray = dealIds?.length && !dealId ? dealIds.reduce((acc, dId) => `${acc}&dealId[]=${dId}`, '') : '';
    return axios.get(`/dictionary/deals?${queryParams}${featuresArray}${lenderIdsArray}${dealsIdsArray}${excludedLenderIdsArray}`);
  },
  getDeal: (dealId) => {
    return axios.get(`/dictionary/deal/${dealId}`);
  },
  getAddressSuggestions: (address) => axios.post('/services/address', { address }),
  getCompaniesSuggestions: (searchString) => axios.get(`/services/entity/${searchString}`),
  registerProfile: (body) => axios.post('/profile/registration', body),
  verifyMobile: ({ mobile, token }) => axios.post('/profile/mobile', { mobile, token }),
  validateInvitation: (id) => axios.get(`/profile/invitation/${id}`),
  inviteApplicant: ({ journeyId, noInvite, body }) => axios.post(`/journeys/${journeyId}/invitations${noInvite ? '?noinvite=true' : ''}`, body),
  getInvites: (journeyId) => axios.get(`/journeys/${journeyId}/invitations`),
  getDealAttributes: () => axios.get('/dictionary/dealAttributes'),
  calculateStampDuty: (body) => axios.post('/stampDuty', body),
  getLMIEstimation: ({ depositAmount, loanTermInYears, occupancyType, firstHomeBuyer, estimatedPropertyValue }) => {
    const queryParams = queryString.stringify({
      depositAmount,
      loanTermInYears,
      occupancyType,
      firstHomeBuyer,
      estimatedPropertyValue
    });
    return axios.get(`/services/getLMIEstimation?${queryParams}`);
  },
  generateLoanSummary: (body) => axios.post('/services/loanSummary', body),
  getCustomSignupByUid: (uid) => axios.get(`/dictionary/customSignup/${uid}`),
};

export const actionTypes = {
  GET_LEND_PROVIDERS: 'GET_LEND_PROVIDERS',
  GET_LEND_PROVIDERS_DEBOUNCE: 'GET_LEND_PROVIDERS_DEBOUNCE',
  UPDATE_LEND_PROVIDER: 'UPDATE_LEND_PROVIDER',
  UPDATE_LEND_PROVIDERS: 'UPDATE_LEND_PROVIDERS',
  GET_SUPER_PROVIDERS: 'GET_SUPER_PROVIDERS',
  GET_SUPER_PROVIDERS_DEBOUNCE: 'GET_SUPER_PROVIDERS_DEBOUNCE',
  GET_REPAYMENT_ESTIMATION: 'GET_REPAYMENT_ESTIMATION',
  GET_REPAYMENT_ESTIMATION_DEBOUNCE: 'GET_REPAYMENT_ESTIMATION_DEBOUNCE',
  GET_DEAL_FEATURES: 'GET_DEAL_FEATURES',
  FIND_DEALS: 'FIND_DEALS',
  FIND_DEALS_BY_IDS: 'FIND_DEALS_BY_IDS',
  GET_ADDRESS_SUGGESTIONS: 'GET_ADDRESS_SUGGESTIONS',
  GET_ADDRESS_SUGGESTIONS_DEBOUNCE: 'GET_ADDRESS_SUGGESTIONS_DEBOUNCE',
  REGISTER_PROFILE: 'REGISTER_PROFILE',
  VERIFY_MOBILE: 'VERIFY_MOBILE',
  VALIDATE_INVITATION: 'VALIDATE_INVITATION',
  INVITE_APPLICANT: 'INVITE_APPLICANT',
  INVITE_APPLICANTS: 'INVITE_APPLICANTS',
  GET_INVITES: 'GET_INVITES',
  GET_COMPANIES_SUGGESTIONS: 'GET_COMPANIES_SUGGESTIONS',
  GET_COMPANIES_SUGGESTIONS_DEBOUNCE: 'GET_COMPANIES_SUGGESTIONS_DEBOUNCE',
  GET_DEAL_ATTRIBUTES: 'GET_DEAL_ATTRIBUTES',
  CALCULATE_STAMP_DUTY: 'CALCULATE_STAMP_DUTY',
  GET_LMI_ESTIMATION: 'GET_LMI_ESTIMATION',
  GENERATE_LOAN_SUMMARY: 'GENERATE_LOAN_SUMMARY',
  GET_CUSTOM_SIGNUP_BY_UID: 'GET_CUSTOM_SIGNUP_BY_UID'
};

function* sagaGetLendProviders({ payload: { searchString, ids, limit, type, inOurPanel, notExcluded, page, sort, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getLendProviders, {searchString, ids, limit, type, inOurPanel, notExcluded, page, sort});
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUpdateLendProvider({ payload: { body, id, onSuccess, onError } }) {
  try {
    const result = yield call(Api.updateLendProvider, {id, body});
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUpdateLendProviders({ payload: { lenders, onSuccess, onError } }) {
  try {
    const results = yield all(lenders.map((l) => call(Api.updateLendProvider, { id: l.id, body: l.body })));
    if (results.every((result) => result.status < 400)) {
      let updates = [];
      results.forEach((result) => updates.push(result.data));
      if (isFunc(onSuccess)) onSuccess(updates);
    } else if (isFunc(onError)) {
      const errorResult = results.find((result) => result.status >= 400);
      onError(errorResult.data);
    }
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetSuperProviders({ payload: { searchString, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getSuperProviders, searchString);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetRepaymentEstimation({ payload: { input, incomplete, onSuccess, onError } }) {
  try {
    if (incomplete && isFunc(onSuccess)) onSuccess(undefined);
    else {
      const result = yield call(Api.getRepaymentEstimation, input);
      if (result.status < 400) {
        if (isFunc(onSuccess)) onSuccess(result.data);
      } else if (isFunc(onError)) onError(result.data);
    }
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetDealFeatures({ payload: { onSuccess, onError } }) {
  try {
    const result = yield call(Api.getDealFeatures);
    if (result.status < 400) {
      yield put({ type: registrationActionType.DEAL_FEATURES, payload: result.data });
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaFindDeals({ payload: {
  lenderType, rateType, repaymentOptions, features, price, deposit, purpose, reason, firstHomeBuyer, page, sort, lenderIds, dealId, dealIds,
  fixedTermMonths, bankType, preferenceInterestRate, preferenceCashBack, limit, personalised, onlyPersonalisedLenders, cashback, excludedLenders, onSuccess, onError
} }) {
  try {
    const result = yield call(Api.findDeals,
      {
        lenderType, rateType, repaymentOptions, features, price, deposit, purpose, reason, firstHomeBuyer, page, sort, lenderIds, dealId,
        dealIds, limit, fixedTermMonths, bankType, preferenceInterestRate, preferenceCashBack, personalised, onlyPersonalisedLenders, cashback, excludedLenders
      }
    );

    if (result.status < 400) {
      yield put({ type: registrationActionType.FOUND_DEALS, payload: result.data });
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);

  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaFindDealsByIds({ payload: { dealIds, limit, onSuccess, onError } }) {
  try {
    const results = yield all(dealIds.map((dealId) => call(Api.findDeals, { dealId, limit })));
    if (results.every((result) => result.status < 400)) {
      let elements = [];
      results.forEach((result) => elements.push(...(result.data?.elements ?? [])));
      if (isFunc(onSuccess)) onSuccess({ elements });
    } else if (isFunc(onError)) {
      const errorResult = results.find((result) => result.status >= 400);
      onError(errorResult.data);
    }

  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetAddressSuggestions({ payload: { address, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getAddressSuggestions, address);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetCompaniesSuggestions({ payload: { searchString, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getCompaniesSuggestions, searchString);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaRegisterProfile({ payload: { body, onSuccess, onError } }) {
  try {
    const response = yield call(Api.registerProfile, body);
    if (response?.data?.token) {
      yield put({ type: registrationActionType.REGISTRATION_TOKEN, payload: response.data.token });
      if (isFunc(onSuccess)) onSuccess(response.data);
    }
    else if (isFunc(onError)) onError(response.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaVerifyMobile({ payload: { mobile, token, onSuccess, onError } }) {
  try {
    const result = yield call(Api.verifyMobile, { mobile, token });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaValidateInvitation({ payload: { id, onSuccess, onError } }) {
  try {
    const result = yield call(Api.validateInvitation, id);
    if (result?.data?.id) {
      yield put({ type: registrationActionType.INVITATION, payload: result.data });
      if (isFunc(onSuccess)) onSuccess(result.data);
    }
    else if (isFunc(onError)) {
      yield put({ type: registrationActionType.INVITATION, payload: undefined });
      onError(result?.status);
    }
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaInviteApplicant({ payload: { journeyId, noInvite, body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.inviteApplicant, { journeyId, noInvite, body });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaInviteApplicants({ payload: { journeyId, noInvite, body, onSuccess, onError } }) {
  try {
    const results = yield all(body.map((invite) => call(Api.inviteApplicant, { journeyId, noInvite, body: invite })));
    if (results.every((result) => result.status < 400)) {
      if (isFunc(onSuccess)) onSuccess(results.map((result) => result.data));
    } else if (isFunc(onError)) {
      const errorResult = results.find((result) => result.status >= 400);
      onError(errorResult.data);
    }
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetInvites({ payload: { journeyId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getInvites, journeyId);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetDealAttributes({ payload: { onSuccess, onError } }) {
  try {
    const result = yield call(Api.getDealAttributes);
    if (result.status < 400) {
      yield put({ type: registrationActionType.DEAL_ATTRIBUTES, payload: result.data });
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaCalculateStampDuty({ payload: { body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.calculateStampDuty, body);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetLMIEstimation({ payload: { depositAmount, loanTermInYears, occupancyType, firstHomeBuyer, estimatedPropertyValue, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getLMIEstimation, { depositAmount, loanTermInYears, occupancyType, firstHomeBuyer, estimatedPropertyValue });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGenerateLoanSummary({ payload: { body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.generateLoanSummary, body);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetCustomSignupByUid({ payload: { uid, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getCustomSignupByUid, uid);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

export const getLendProviders = payload => ({ type: actionTypes.GET_LEND_PROVIDERS, payload });
export const getLendProvidersWithDebounce = payload => ({ type: actionTypes.GET_LEND_PROVIDERS_DEBOUNCE, payload });
export const updateLendProvider = payload => ({ type: actionTypes.UPDATE_LEND_PROVIDER, payload });
export const updateLendProviders = payload => ({ type: actionTypes.UPDATE_LEND_PROVIDERS, payload });
export const getSuperProviders = payload => ({ type: actionTypes.GET_SUPER_PROVIDERS, payload });
export const getSuperProvidersWithDebounce = payload => ({ type: actionTypes.GET_SUPER_PROVIDERS_DEBOUNCE, payload });
export const getRepaymentEstimation = payload => ({ type: actionTypes.GET_REPAYMENT_ESTIMATION, payload });
export const getRepaymentEstimationWithDebounce = payload => ({ type: actionTypes.GET_REPAYMENT_ESTIMATION_DEBOUNCE, payload });
export const getDealFeatures = payload => ({ type: actionTypes.GET_DEAL_FEATURES, payload });
export const findDeals = payload => ({ type: actionTypes.FIND_DEALS, payload });
export const findDealsByIds = payload => ({ type: actionTypes.FIND_DEALS_BY_IDS, payload });
export const getAddressSuggestions = payload => ({ type: actionTypes.GET_ADDRESS_SUGGESTIONS, payload });
export const getAddressSuggestionsWithDebounce = payload => ({ type: actionTypes.GET_ADDRESS_SUGGESTIONS_DEBOUNCE, payload });
export const getCompaniesSuggestions = payload => ({ type: actionTypes.GET_COMPANIES_SUGGESTIONS, payload });
export const getCompaniesSuggestionsWithDebounce = payload => ({ type: actionTypes.GET_COMPANIES_SUGGESTIONS_DEBOUNCE, payload });
export const registerProfile = payload => ({ type: actionTypes.REGISTER_PROFILE, payload });
export const verifyMobile = payload => ({ type: actionTypes.VERIFY_MOBILE, payload });
export const validateInvitation = payload => ({ type: actionTypes.VALIDATE_INVITATION, payload });
export const getInvites = payload => ({ type: actionTypes.GET_INVITES, payload });
export const inviteApplicant = payload => ({ type: actionTypes.INVITE_APPLICANT, payload });
export const inviteApplicants = payload => ({ type: actionTypes.INVITE_APPLICANTS, payload });
export const getDealAttributes = payload => ({ type: actionTypes.GET_DEAL_ATTRIBUTES, payload });
export const calculateStampDuty = payload => ({ type: actionTypes.CALCULATE_STAMP_DUTY, payload });
export const getLMIEstimation = payload => ({ type: actionTypes.GET_LMI_ESTIMATION, payload });
export const generateLoanSummary = payload => ({ type: actionTypes.GENERATE_LOAN_SUMMARY, payload });
export const getCustomSignupByUid = payload => ({ type: actionTypes.GET_CUSTOM_SIGNUP_BY_UID, payload });


const registrationSagas = [
  debounce(900, actionTypes.GET_LEND_PROVIDERS_DEBOUNCE, sagaGetLendProviders),
  takeLatest(actionTypes.GET_LEND_PROVIDERS, sagaGetLendProviders),
  takeEvery(actionTypes.UPDATE_LEND_PROVIDER, sagaUpdateLendProvider),
  takeEvery(actionTypes.UPDATE_LEND_PROVIDERS, sagaUpdateLendProviders),
  debounce(900, actionTypes.GET_SUPER_PROVIDERS_DEBOUNCE, sagaGetSuperProviders),
  takeLatest(actionTypes.GET_SUPER_PROVIDERS, sagaGetSuperProviders),
  debounce(900, actionTypes.GET_REPAYMENT_ESTIMATION_DEBOUNCE, sagaGetRepaymentEstimation),
  takeLatest(actionTypes.GET_REPAYMENT_ESTIMATION, sagaGetRepaymentEstimation),
  takeLatest(actionTypes.GET_DEAL_FEATURES, sagaGetDealFeatures),
  takeEvery(actionTypes.FIND_DEALS, sagaFindDeals),
  takeEvery(actionTypes.FIND_DEALS_BY_IDS, sagaFindDealsByIds),
  takeLatest(actionTypes.GET_ADDRESS_SUGGESTIONS, sagaGetAddressSuggestions),
  debounce(900, actionTypes.GET_ADDRESS_SUGGESTIONS_DEBOUNCE, sagaGetAddressSuggestions),
  takeLatest(actionTypes.REGISTER_PROFILE, sagaRegisterProfile),
  takeLatest(actionTypes.VERIFY_MOBILE, sagaVerifyMobile),
  takeLatest(actionTypes.VALIDATE_INVITATION, sagaValidateInvitation),
  takeLatest(actionTypes.GET_INVITES, sagaGetInvites),
  takeEvery(actionTypes.INVITE_APPLICANT, sagaInviteApplicant),
  takeEvery(actionTypes.INVITE_APPLICANTS, sagaInviteApplicants),
  takeLatest(actionTypes.GET_COMPANIES_SUGGESTIONS, sagaGetCompaniesSuggestions),
  debounce(900, actionTypes.GET_COMPANIES_SUGGESTIONS_DEBOUNCE, sagaGetCompaniesSuggestions),
  takeLatest(actionTypes.GET_DEAL_ATTRIBUTES, sagaGetDealAttributes),
  takeLatest(actionTypes.CALCULATE_STAMP_DUTY, sagaCalculateStampDuty),
  takeEvery(actionTypes.GET_LMI_ESTIMATION, sagaGetLMIEstimation),
  takeLatest(actionTypes.GENERATE_LOAN_SUMMARY, sagaGenerateLoanSummary),
  takeLatest(actionTypes.GET_CUSTOM_SIGNUP_BY_UID, sagaGetCustomSignupByUid),
];

export default registrationSagas;
