const defaultState = {
  user: null,
  isLoadedUser: false,
  selectedUser: null,
  isLoadedSelectedUser: false,
  isPartnerCustomerMode: false
};

export const actionTypes = {
  USER: 'USER',
  IS_LOADED_USER: 'IS_LOADED_USER',
  SELECTED_USER: 'SELECTED_USER',
  IS_LOADED_SELECTED_USER: 'IS_LOADED_SELECTED_USER',
  IS_PARTNER_CUSTOMER_MODE: 'IS_PARTNER_CUSTOMER_MODE'
};

const userActions = function (state = defaultState, { type, payload }) {
  switch (type) {
    case actionTypes.USER:
      return {
        ...state,
        user: payload
      };

    case actionTypes.IS_LOADED_USER:
      return {
        ...state,
        isLoadedUser: payload
      };

    case actionTypes.SELECTED_USER:
      return {
        ...state,
        selectedUser: payload
      };

    case actionTypes.IS_LOADED_SELECTED_USER:
      return {
        ...state,
        isLoadedSelectedUser: payload
      };

    case actionTypes.IS_PARTNER_CUSTOMER_MODE:
      return {
        ...state,
        isPartnerCustomerMode: payload
      };

    default:
      return state;
  }
};

export default userActions;

export const setIsPartnerCustomerMode = payload => ({ type: actionTypes.IS_PARTNER_CUSTOMER_MODE, payload });
