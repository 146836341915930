import { call, takeLatest, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import { isFunc } from '@utils/functions';

const Api = {
  getHelpContents: ({ page, limit, userId }) =>
    axios.get(`/contents/personalised?limit=${limit ?? 3}${page && page > 1 ? '&page=' + page : ''}${userId ? '&user=' + userId : ''}`
    ),
  getStepsByJourneyId: (journeyId) => axios.get(`/journeys/${journeyId}/steps`),
  getFeatureFlags: () => axios.get(`/services/featureFlags`),
  getCampaignByUid: ({ uid, userId }) => axios.get(`/campaignByUid/${uid}${!!userId ? `?userId=${userId}` : ''}`),
  putUserAction: ({ uid, body }) => axios.post(`/campaignByUid/${uid}/userAction`, body),
};

export const actionTypes = {
  GET_HELP_CONTENTS: 'GET_HELP_CONTENTS',
  GET_STEPS_BY_JOURNEY_ID: 'GET_STEPS_BY_JOURNEY_ID',
  GET_FEATURE_FLAGS: 'GET_FEATURE_FLAGS',
  GET_CAMPAIGN_BY_UID: 'GET_CAMPAIGN_BY_UID',
  PUT_USER_ACTION: 'PUT_USER_ACTION'
};

function* sagaHelpContents({ payload: { page, limit, userId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getHelpContents, { page, limit, userId });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetStepsByJourneyId({ payload: { journeyId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getStepsByJourneyId, journeyId);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetFeatureFlags({ payload: { onSuccess, onError } }) {
  try {
    const result = yield call(Api.getFeatureFlags);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else {
      if (isFunc(onError)) onError(result.data);
    }
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetCampaignByUid({ payload: { uid, userId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getCampaignByUid, { uid, userId });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaPutUserAction({ payload: { uid, body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.putUserAction, {uid, body});
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

export const getHelpContents = payload => ({ type: actionTypes.GET_HELP_CONTENTS, payload });
export const getStepsByJourneyId = payload => ({ type: actionTypes.GET_STEPS_BY_JOURNEY_ID, payload });
export const getFeatureFlags = payload => ({ type: actionTypes.GET_FEATURE_FLAGS, payload });
export const getCampaignByUid = payload => ({ type: actionTypes.GET_CAMPAIGN_BY_UID, payload });
export const putUserActionToCampaign = payload => ({ type: actionTypes.PUT_USER_ACTION, payload });

const dashboardSagas = [
  takeLatest(actionTypes.GET_HELP_CONTENTS, sagaHelpContents),
  takeEvery( actionTypes.GET_STEPS_BY_JOURNEY_ID, sagaGetStepsByJourneyId),
  takeLatest(actionTypes.GET_FEATURE_FLAGS, sagaGetFeatureFlags),
  takeEvery( actionTypes.GET_CAMPAIGN_BY_UID, sagaGetCampaignByUid),
  takeEvery( actionTypes.PUT_USER_ACTION, sagaPutUserAction),
];

export default dashboardSagas;
