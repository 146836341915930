import React, {lazy} from 'react';

import { FreeRoute } from '../auth/components';
//import { Journey } from './pages';
import reducers from './reducers';
import sagas from './sagas';


const Journey = lazy(() => import('./pages/Journey'));

const registrationModule = {
  routes: [
    <FreeRoute
      key="journey"
      exact
      path="/journey/signup/:customSignupUid"
      component={Journey}
      headerType="registrationWizard"
      componentProps={{ isCustomSignup: true }}
    />,
    <FreeRoute
      key="journey"
      exact
      path="/journey/:id/:step"
      component={Journey}
      headerType="registrationWizard"
    />,
    <FreeRoute
      key="journey"
      exact
      path="/journey/:step"
      component={Journey}
      headerType="registrationWizard"
    />,
    <FreeRoute
      key="journey"
      exact
      path="/journey"
      component={Journey}
      headerType="registrationWizard"
    />,
  ],
  reducers: {
    journey: reducers.journey
  },
  sagas
};

export default registrationModule;
