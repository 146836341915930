import reducers from './reducers';
import sagas from './sagas';
import { AuthRoute } from '../auth/components';
import React, {lazy} from 'react';
import {USER_ROLE} from "@utils/authorisation";
//import { CustomersDashboard, Identity } from './pages';


const CustomersDashboard = lazy(() => import('./pages/CustomersDashboard'));
const Identity = lazy(() => import('./pages/Identity'));

const userModule = {
  routes: [
    <AuthRoute
      key="customers-dashboard"
      exact
      path="/customers-dashboard"
      component={CustomersDashboard}
      headerType={'private'}
      authorization={[USER_ROLE.Admin, USER_ROLE.OperationsManager, USER_ROLE.Supervisor, USER_ROLE.Conveyancer, USER_ROLE.Specialist, USER_ROLE.Audit, USER_ROLE.Partner_Admin, USER_ROLE.Partner_Supervisor, USER_ROLE.Partner_Member]}
    />,
    <AuthRoute key="customer-identity" exact path="/customer-identity/:userId" component={Identity} headerType={'private'} isSpecialistOnly />,
  ],
  navMenuItems: [
    {
      name: 'Customers',
      path: '/customers-dashboard',
      isForSpecialist: true,
      allowedRoles: [USER_ROLE.Admin, USER_ROLE.OperationsManager, USER_ROLE.Supervisor, USER_ROLE.Conveyancer, USER_ROLE.Specialist, USER_ROLE.Audit],
      order: 3
    },
    {
      name: 'Customers',
      path: '/customers-dashboard',
      isForPartner: true,
      notForPartnerCustomer: true,
      allowedRoles: [USER_ROLE.Partner_Admin, USER_ROLE.Partner_Supervisor, USER_ROLE.Partner_Member],
      order: 3
    },
  ],
  sagas,
  reducers: { user: reducers }
};

export default userModule;
